.search_table_container {
  background-color: rgb(243, 243, 243);
  padding: 2rem 1.5rem;
  position: absolute;
  top: 65%;
  left: 60%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  border-radius: 2rem;
  width: 60%;
}

.search_table {
  width: 100%;
  margin-top: 1rem;
}
.search_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}
.search_table tr {
  cursor: pointer;
}
.search_table td,
.search_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.search_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.search_table tr:hover {
  background-color: #ddd;
}

.search_table th {
  padding: 12px 8px;
  text-align: left;
  background-color: #5f9be0;
  color: white;
}
