.setting_section ul li {
  font-weight: 900;
  padding: 20px;
  border-bottom: 1px dashed rgb(145, 145, 145);
  list-style-type: none;
  font-weight: 900;
}
ul li {
  list-style-type: none;
}

.setting_section .logout_btn {
  color: #fff;
  background-color: rgb(50, 175, 50);
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
}
.setting_section {
  width: 100%;
}
.setting_social_icon img {
  cursor: pointer;
}
.logout_btn svg {
  margin-right: 10px;
}

.setting_social_icon img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.setting_item {
  color: #000;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}

.setting_item:hover {
  color: #000;
  text-decoration: none;
}
.setting_item span {
  text-decoration: none;
  font-weight: 900;
  margin-left: 0;
}

.setting_item svg {
  font-size: clamp(1.1rem, 1.7vw, 2rem);
}

.setting_item {
  color: #000;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}
.setting_item:hover {
  color: #000;
}

.setting_item svg {
  font-size: clamp(1.1rem, 1.7vw, 2rem);
}

.contact_info_item {
  display: flex;
}
.contact_info_info {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
}
.contact_info_icon {
  margin-top: 10px;
  width: 32px;
  max-width: 100%;
  height: 32px;
}

.contact_info_icon img {
  width: inherit;
  height: auto;
}

.profile_edit_input {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
}
.profile_edit_input .graduation input {
  border-radius: 10px;
  background-color: #e5e5e5;
  border: none;
  padding: 10px;
}
.profile_edit_input input {
  border-radius: 10px;
  background-color: #e5e5e5;
  border: none;
  padding: 10px;
}
.profile_edit_input textarea {
  border-radius: 10px;
  background-color: white;
  border: 1px solid #e5e5e5;

  padding: 10px;
}
.profile_edit_input input {
  height: 37px;
}
.profile .profile_edit_input input:focus {
  outline: none;
}
.profile_edit_input input::placeholder,
.profile_edit_input textarea::placeholder {
  color: #b0b9cc;
}
.profile_edit_input label {
  font-size: clamp(1rem, 1.2vw, 1.3rem);
}

.check_modal h1 {
  font-size: clamp(2rem, 2vw, 2.4rem);
}
.check_modal h4 {
  font-size: clamp(0.9rem, 1.5vw, 2rem);
}
.check_modal img {
  width: 120px;
  height: auto;
}
.check_modal .audio_test {
  width: 200px;
  height: auto;
}
.check_modal .net_gif {
  transform: rotate(-43deg);
}
.form-check-label {
  font-weight: 100;
  font-size: clamp(1.3rem, 1.8vw, 2rem);
}
.form-check-input {
  width: 20px;
  height: 20px;
}
.form-check-label {
  margin-left: 30px;
}
.slot_cancel {
  background-color: #ff0000;
  color: white !important;
}
.slot_available {
  background-color: #43dcff;
  color: white !important;
}
.slot_booked {
  background-color: #3f51b5;
  color: white !important;
}
.slot_paid_booked {
  background-color: #81007f;
  color: white !important;
}
.slot_locked {
  background-color: #09db31;
  color: white !important;
}
.badge_modal .modal_content {
  padding: 10px;
}
.back_title {
  font-size: clamp(1.1rem, 2.4vw, 2rem);
}

.tech_support li {
  margin: 50px 0;
}

.password_change {
  width: 50%;
  margin: 40px 80px;
}
.change_password_form .form-group {
  display: flex;
  flex-direction: column;
}

.change_password_form input {
  border: none;
  border-bottom: 1px solid #000000;
  padding: 5px 10px;
  outline: none;
}
.setting_section ul li {
  font-weight: 900;
  padding: 20px;
  border-bottom: 1px dashed rgb(145, 145, 145);
}

.setting_section .logout_btn {
  color: #fff;
  background-color: rgb(50, 175, 50);
  padding: 10px 20px;
  border-radius: 5px;
}
.setting_social_icon img {
  cursor: pointer;
}
.logout_btn svg {
  margin-right: 10px;
}

.setting_social_icon img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.setting_section ul,
li {
  padding-left: 0%;
}
@media screen and (min-width: 1200px) {
  .setting_section {
    padding-right: 10%;
  }
}
@media screen and (min-width: 780px) and (max-width: 1200px) {
  .setting_section {
    padding-right: 0%;
    padding-left: 2%;
  }
}
@media screen and (max-width: 780px) {
  .setting_social_icon img {
    margin-right: 5px;
    padding-left: 0%;
  }
  .password_change {
    width: 50%;
    margin: 40px 20px;
  }
  /* .setting_section {
    padding-top: 5%;
  } */
}

@media screen and (max-width: 500px) {
  .password_change {
    width: 90%;
    margin: 40px 20px;
  }
}

@media screen and (min-width: 1380px) {
  .badge_modal .modal_content {
    width: 60%;
  }
}
@media screen and (max-width: 1100px) {
  .badge_modal .teacher_badge {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 780px) {
  .contact_info_icon {
    width: 25px;
    height: 25px;
  }
  .contact_info_info {
    margin-left: 25px;
  }
}

@media screen and (max-width: 500px) {
  .badge_modal .teacher_badge {
    width: 100px;
    height: 100px;
  }
}
