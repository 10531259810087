.actionBox,
.statusBox {
  background-color: #fcf9f7;
  border: 2px solid black;
  border-radius: 30px;
  /* box-shadow: 0px 0px 50px -30px white; */
  width: 30%;
  /* height: 5%; */
  position: fixed;
  left: 60%;
  top: 20%;
  transform: translate(-45%, 10%);
  padding: 2rem 2.5rem;
  z-index: 1000;
}

@media screen and (max-width: 950px) {
  .buttons {
    flex-direction: column;
  }
  .actionBox,
  .statusBox {
    width: 40%;
  }
}
@media screen and (max-width: 780px) {
  .actionBox,
  .statusBox {
    width: 60%;
    left: 45%;
  }
}
@media screen and (max-width: 400px) {
  .actionBox,
  .statusBox {
    width: 70%;
    left: 45%;
  }
}
