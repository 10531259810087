body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  counter-reset: Serial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

:root {
  --theme_color: #3f51b5;
}

table {
  border-collapse: separate;
}
table tr,
th {
  font-size: 14px;
}
/* tr td:first-child:before
{
  counter-increment: Serial;     
  content: counter(Serial); 
} */
