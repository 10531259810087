.save_button {
  color: white;
  border: 2px solid green;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: green;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.save_button_deactive {
  color: white;
  border: 2px solid lightgray;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: lightgray;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.profile_info_add {
  color: green;
  margin: 1.9rem 1rem;
}

.profile_info_add_btn {
  cursor: pointer;
}

.profile_info_slot_list .badge_item {
  padding: 3px !important;
  margin: 0 15px 10px 0;
}
