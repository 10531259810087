#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 5%;
}
#customers th {
  padding: 8px !important;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
}

.curri_delete {
  color: white;
  border: red;
  background-color: red;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}

.curri_edit {
  color: white;
  border: red;
  background-color: red;
  padding: 5px;
  border-radius: 5px;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
.curriculum_delete_button {
  border: red;
  color: white;
  background-color: red;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}
#customers th {
  text-align: left;
  background-color: #848584;
  color: white;
}
select {
  color: black;
}
