/* .dx-sidebar {
  height: 100vh;
  background-color: #3f51b5;
  position: fixed !important;
  display: inline;
  width: 250px;
  padding: 1rem 0;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}

.dx-sidebar img {
  width: 15%;
}
.dx-sidebar a {
  text-decoration: none;
  color: white;
  margin: 2% 10%;
  padding: 2.5% 4%;
  border-radius: 15px;
}
.dx-sidebar svg {
  width: 25px;
  height: 25px;
}

.dx-sidebar::-webkit-scrollbar {
  width: 8px;
}

.dx-sidebar::-webkit-scrollbar-track {
  background: none;
}

.dx-sidebar::-webkit-scrollbar-thumb {
  background: #5576ff;
  border-radius: 5px;
}

.dx-sidebar::-webkit-scrollbar-thumb:hover {
  background: #3d63f8;
}

.nav-link {
  padding: 5%;
  margin: 2% 9%;
  border-radius: 5%;
}
.selected {
  font-weight: 700;
  background-color: white;
}
.selected img {
  filter: brightness(0) saturate(100%) invert(28%) sepia(10%) saturate(7251%)
    hue-rotate(206deg) brightness(107%) contrast(93%);
}
a.selected {
  color: #3f51b5;
}

span {
  margin-left: 10px;
}
.logout_button {
  background-color: green;
  color: white;
  border: 1px solid green;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
}
.toggle {
  display: none;
}
.TechoImg {
  width: 20vw;
}
.horizontal-navBar {
  background-color: #3f51b5;
  width: 250px;
} */
/* @media only screen and (max-width: 780px) {
  .toggle {
    display: block;
    position: relative;
    left: "-500%";
  }
  .main-toggle {
    width: "100%";
    display: flex;
    justify-content: start;
  }
  .sidebar_nav_link {
    display: flex;
  }
  .horizontal-navBar {
    height: 60px;
    width: 100%;
    background-color: #3f51b5;
  }
  .dx-sidebar {
    display: none;
  } 
  .TechoImg {
    width: 15vw;
  }
} */
/* @media only screen and (max-width: 780px) {
  .main_content {
    margin-left: 0;
    margin-top: 130px;
  }
  .navBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .web_logo {
    display: none;
  }
  .navBar-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sidebar {
    position: fixed;
    top: 80px;
    left: -100%;
    transition: 850ms;
  }
  .nav-text a {
    font-size: 15px;
  }
  .nav-text {
    height: 65px;
  }
} */
.main_content {
  margin-top: 50px;
  margin-bottom: 150px;
  margin-left: 250px;
}

.navBar {
  background-color: #3f51b5;
  height: 80px;
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navBar img {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
}

.menu-Bars {
  font-size: 2rem;
  background-color: #3f51b5;
  color: #fff;
}

.menu-Bars:hover,
.active_link {
  color: #fff;
}

.sidebar {
  background-color: #3f51b5;
  width: 150px !important;
  min-width: 280px;
  overflow-y: scroll;
  position: fixed;
  z-index: 50;
  display: flex;
  padding-right: 0;
  top: 0;
  bottom: 0;
}
.sidebar ul {
  padding-left: 0%;
}
.sidebar::-webkit-scrollbar {
  width: 8px;
}
.sidebar::-webkit-scrollbar-track {
  background: none;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #5576ff;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #3d63f8;
}

.sidebar.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  border-radius: 4px 0 0 4px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.2px;
}
.sidebar svg {
  width: 28px;
  height: auto;
}

.no_svg_clr svg path {
  fill: none !important;
  stroke: #fff;
}

.nav-text:hover svg path,
.nav-text a:hover,
.active_link svg path,
.active_link a {
  background-color: #fff;
  color: #3f51b5;
  fill: #3f51b5 !important;
}

.no_svg_clr:hover svg path,
.no_svg_clr a:hover,
.active_link.no_svg_clr svg path,
.active_link.no_svg_clr a {
  fill: none !important;
  stroke: #3f51b5 !important;
}
.nav-menu-items {
  width: 100%;
}

.navBar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: none;
}

.sidebar-title {
  margin-left: 16px;
}
.col-md-4 {
  width: 16%;
}
@media only screen and (max-width: 780px) {
  .main_content {
    margin-left: 0;
    margin-top: 130px;
  }
  .navBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .web_logo {
    display: none;
  }
  .navBar-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sidebar {
    position: fixed;
    top: 80px;
    left: -100%;
    transition: 850ms;
    width: 16% !important;
  }
  .nav-text a {
    font-size: 15px;
  }
  .nav-text {
    height: 65px;
  }
}
