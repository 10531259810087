.edit_profile_container {
  display: flex;
  padding: 1vh 1vw;
  justify-content: space-around;
  align-items: center;
}

.edit_profile img.profile_picture {
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.edit_profile input,
.edit_profile select,
.edit_profile textarea {
  width: 100%;
  display: block;
  margin: 0.8rem;
  background-color: #e5e5e5;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.edit_profile textarea {
  padding: 1rem;
}

.edit_profile form input[type='submit'],
.edit_profile button {
  width: fit-content;
  padding: 5px 15px;
  background-color: #592b89;
  color: white;
  font-weight: 900;
  margin: 1rem;
  border: none;
  border-radius: 10px;
  display: inline-block;
}
.edit_profile form input[type='file'] {
  width: fit-content;
  display: inline-block;
}

.edit_profile button {
  background-color: #00b13b;
}

.edit_profile li {
  list-style-type: none;
}

.edit_profile input[type='radio'] {
  width: fit-content;
}
