.login_page_form_group {
  width: 100%;
}

.login_page .form-control {
  border-radius: 15px;
  border: 1px solid #3f51b5;
  padding: 20px 15px;
  font-size: clamp(0.8rem, 2vw, 1rem);
  margin: 0;
}

.login_page .form-control:focus {
  color: none;
  background-color: inherit;
  border-color: #3f51b5;
  outline: none;
  box-shadow: none;
}
.btn_signin {
  padding: 10px 35px;
  border-radius: 20px;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
}
@media screen and (min-width: 780px) {
  .login_page .form-control {
    border: 1px solid #6b0080;
    padding: 25px 15px;
  }
  .btn_signin {
    padding: 10px 45px;
    border-radius: 20px;
  }
}
