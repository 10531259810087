.attach_file {
	transform: rotate(45deg);
}
.open_button {
	border: 2px solid yellow;
	color: black;
	padding: 5px;
}
.open_button:focus {
	border: 2px solid yellow;
	background-color: yellow;
	color: black;
	padding: 5px;
}
.close_button {
	border: 2px solid lightblue;
	color: black;
	padding: 5px;
}
.close_button:focus {
	border: 2px solid lightblue;
	background-color: lightblue;
	color: black;
	padding: 5px;
}
.inprog_button {
	border: 2px solid lightgreen;
	color: black;
	padding: 5px;
}
.inprog_button:focus {
	border: 2px solid lightgreen;
	background-color: lightgreen;
	color: black;
	padding: 5px;
}
.modal-body p {
	word-wrap: break-word;
}
.btn {
	border: none !important;
	outline: none !important;
}

.delete_buttton_ticket {
	color: white;
	background-color: red;
	margin: 5px;
	padding: 5px;
	border-radius: 5px;
	border: green;
}
.file_item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
}

.open_button_enable {
	border: 2px solid yellow;
	background-color: yellow;
	color: black;
	padding: 5px;
}
.close_button_enable {
	border: 2px solid lightblue;
	background-color: lightblue;
	color: black;
	padding: 5px;
}
.inprog_button_enable {
	border: 2px solid lightgreen;
	background-color: lightgreen;
	color: black;
	padding: 5px;
}
