.feedback_on_paid_student_container {
  background-color: white;
  border: 20px;
  box-shadow: 0px 5px 20px -10px black;
  width: 80%;
  margin-left: 2vw;
  margin-top: 4vh;
  padding: 2rem;
}

.feedback_on_paid_student_container input {
  width: 30%;
  border: 1px solid #3f51b4;
  border-radius: 5px;
}
.feedback_on_paid_student_container input:focus {
  border: 2px solid #3f51b4;
}
.feedback_on_paid_student_container input[type='submit'] {
  background-color: #46db30;
  font-weight: 900;
  color: white;
  width: 3rem;
  border: none;
  border-radius: 5px;
  margin-left: 1rem;
}

.individual_feedback_on_paid_student {
  margin-top: 1rem;
}
.individual_feedback_on_paid_student span {
  margin-left: 0;
  font-size: 0.8rem;
  opacity: 0.5;
}
