.blogThumbNail {
	width: 80%;
	height: 300px;
	object-fit: cover;
}
.displayContent {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
