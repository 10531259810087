.tk_dropdown .dropdown button {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  border: 1.0498px solid #cccccc;
  height: 40px;
  position: relative;
}

.tk_dropdown .dropdown-toggle::after {
  content: none;
}
.tk_dropdown .dropdown-menu {
  border: 1px dashed rgb(158, 158, 158);
  transform: none !important;
}
.tk_dropdown .dropdown-menu .dropdown-item {
  border: 1px dashed rgb(185, 185, 185);
}
.tk_dropdown .dropdown-menu .dropdown-item:hover {
  cursor: pointer;
  color: #fff;
  background-color: #071fff;
}

.tk_dropdown_item,
.tk_dropdown .dropdown-menu {
  background-color: #f7fcfb;
}
.tk_dropdown svg {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tk_dropdown .btn:focus {
  outline: 0;
  box-shadow: none;
}
