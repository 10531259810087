.mainTable {
  overflow-x: auto;
  width: 100%;
  position: relative;
  left: 0%;
}
table {
  border-collapse: collapse;
  width: 100%;
  height: 50%;
  border-spacing: 0;
}
th,
td {
  text-align: left;
}
