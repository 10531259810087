input {
  width: 100%;
  margin: 5px;
  padding: 5px;
}

.badge_item {
  width: fit-content;
  background-color: #e5e5e5;
  padding: 3px 0px 3px 10px;
  border-radius: 5px;
  margin: 0 20px 10px 0;
}
.badge_item svg {
  float: right;
  cursor: pointer;
}
.delete_button {
  background-color: red;
  border-radius: 10%;
  color: white;
  margin: 10px;
  border: 2px solid red;
}
