.back_icon {
  border: 1px solid black;
  border-radius: 10px;
  padding: 0px 0.5rem;
  margin-left: 0;
  cursor: pointer;
}

.referral_individual_details {
  margin-top: 1rem;
}
.referral_individual_details p {
  color: #3f51b5;
}
.referral_table_individual {
  width: 100%;
  border-collapse: collapse;
}
.referral_table_individual tr:nth-child(odd) {
  background-color: rgb(240, 240, 240);
}
.referral_table_individual th {
    color:#212529;
  }
.referral_table_individual td {
  padding: 8px;
}
.referral_table_individual td button {
  border: none;
  background-color: white;
}
.referral_table_individual td button span:first-child {
  background-color: #ff0000;
  border: none;
  border-radius: 3px 0px 0px 3px;
}
.referral_table_individual td button span {
  background-color: #09db31;
  border: none;
  margin: 0;
  color: white;
  padding: 3px;
  border-radius: 0px 3px 3px 0;
}
