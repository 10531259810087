.registration_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.registration_action_item {
  margin-left: 20px;
  background-color: rgb(236, 236, 236);
  padding: 1px 10px;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
}
.registration_tabel_action {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 10px;
}
.registration_tabel_action .tk_dropdown label {
  display: none;
}
.registration_tabel_action svg {
  cursor: pointer;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
}
#customers table {
  width: 100%;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}
#customers tr {
  cursor: pointer;
}
#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  text-align: left;
  /* background-color: #5f9be0; */
  color: white;
}
.selected_row {
  background-color: #8feb8c !important;
}
.delete_buttton {
  color: white;
  background-color: red;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border: red;
}
