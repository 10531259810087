.alertBoxNew {
  box-shadow: 0px 3px 30px -15px black;
  width: 60%;
  /* height: 5%; */
  position: absolute;
  left: 55%;
  top: 0%;
  transform: translate(-45%, 10%);
  padding: 2rem 2.5rem;
  z-index: 101;
}
