.main_content {
  margin-top: 50px;
  margin-bottom: 150px;
  margin-left: 250px;
}

.navbar {
  background-color: #3f51b5;
  height: 80px;
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navbar img {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
}

.menu-bars {
  font-size: 2rem;
  background-color: #3f51b5;
  color: #fff;
}

.menu-bars:hover,
.active_link {
  color: #fff;
}

.sidebar {
  background-color: #3f51b5;
  width: 150px;
  min-width: 250px;
  overflow-y: scroll;
  position: fixed;
  z-index: 100;
  display: flex;
  padding-right: 0;
  top: 0;
  bottom: 0;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  border-radius: 4px 0 0 4px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.2px;
}
.sidebar svg {
  width: 28px;
  height: auto;
}

.no_svg_clr svg path {
  fill: none !important;
  stroke: #fff;
}

.nav-text:hover svg path,
.nav-text a:hover,
.active_link svg path,
.active_link a {
  background-color: #fff;
  color: #3f51b5;
  fill: #3f51b5 !important;
}

.no_svg_clr:hover svg path,
.no_svg_clr a:hover,
.active_link.no_svg_clr svg path,
.active_link.no_svg_clr a {
  fill: none !important;
  stroke: #3f51b5 !important;
}
.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: none;
}

.sidebar-title {
  margin-left: 16px;
}

@media only screen and (max-width: 780px) {
  .main_content {
    margin-left: 0;
    margin-top: 130px;
  }
  .navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .web_logo {
    display: none;
  }
  .navbar-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sidebar {
    position: fixed;
    top: 80px;
    left: -100%;
    transition: 850ms;
  }
  .nav-text a {
    font-size: 15px;
  }
  .nav-text {
    height: 65px;
  }
}

/*  */

.base {
  margin-left: 20%;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .base {
    margin-left: 25%;
  }
}
@media screen and (min-width: 780px) and (max-width: 1200px) {
  .base {
    padding-right: 0%;
    padding-left: 10%;
  }
  .t-slot {
    padding-left: 0%;
  }
}
@media screen and (min-width: 780px) and (max-width: 950px) {
  .base {
    padding-left: 15%;
  }
}
@media screen and (max-width: 780px) {
  .base {
    margin-left: 0%;
    padding-top: 5%;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (min-width: 1380px) {
}
@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 780px) {
  .base {
    margin-top: 50px;
  }
  /* .t-slot {
    display: flex;
    justify-content: center;
  } */
}

@media screen and (max-width: 500px) {
  .base {
    margin-top: 50px;
  }
}
