.mainAvail {
  width: 100%;
}
.availTable {
  overflow-x: auto;
  width: 100%;
  position: relative;
  left: 0%;
}
table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}
th,
td {
  text-align: left;
}
.slot_calendar {
  margin: 0;
  margin-top: 3px;
}
.gradeDiv {
  margin-top: 0px;
}
.threeAvail {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}
.back {
  position: relative;
  left: 10%;
}
@media screen and (min-width: 1200px) {
  .twoAvail {
    position: relative;
    left: -10%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .availTable {
    position: relative;
    left: 5%;
  }

  .threeAvail {
    position: relative;
    left: 5%;
  }
}
@media screen and (max-width: 1200px) {
  .availTable {
    position: relative;
    left: -10%;
  }
  .threeAvail {
    position: relative;
    left: -10%;
  }
  .dateAvail {
    font-size: 1.5rem;
  }
  .gradeDiv {
    margin: 0;
    padding: 0;
  }
  .back {
    position: relative;
    left: -5%;
  }
}
@media screen and (max-width: 1100px) {
  .twoAvail {
    display: flex;
    flex-direction: column;
  }
  .gradeDiv {
    margin: 0;
    padding: 0;
  }
}
@media screen and (max-width: 950px) {
  .mainAvail {
    margin: 10%;
  }
}
@media screen and (max-width: 780px) {
  .mainAvail {
    margin-left: 10%;
    margin-top: 0;
  }
  .threeAvail {
    display: flex;
    justify-content: space-around;
  }
  .back {
    position: relative;
    left: 5%;
  }
}
@media screen and (max-width: 550px) {
  .threeAvail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .back {
    position: relative;
    left: 0%;
  }
}
