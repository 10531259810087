#users {
  width: 100%;
}
#users {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}
#users tr {
  cursor: pointer;
}

#users td,
#users th {
  border: 1px solid #ddd;
  padding: 12px;
}

#users tr:nth-child(even) {
  background-color: #f2f2f2;
}

#users tr:hover {
  background-color: #ddd;
}

#users th {
  padding: 12px 12px;
  text-align: left;
  background-color: #5f9be0;
  color: white;
}
