.slot_today_booked {
  /* width: 45%; */
}
.slot_today_booked {
  background-color: #3f51b5;
}
.slot_today_available {
  width: 45%;
  background-color: rgb(67, 240, 105);
}

.slot_today_booked {
  width: 130px;
  margin-right: 50px;
}
.slot_today_available {
  width: 130px;
}
.slot_calendar {
  margin-right: 50px;
}

.slot {
  min-width: 120px;
  width: 13%;
  border-radius: 10px;
  padding: 10px;
  margin: 15px 30px 15px 0;
  color: white;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.slot_available_number {
  position: absolute;
  background-color: red;
  border-radius: 50%;
  font-size: 16px;
  width: 25px;
  height: 25px;
  top: -8px;
  right: -10px;
}
.slot.available {
  background-color: rgb(67, 240, 105);
}
.slot.unavailable {
  background-color: rgb(216, 216, 216);
}
.slot.violate {
  background-color: #3f51b5;
}

.slot_table td,
th {
  background-color: rgb(231, 231, 231);
  color: #2e3c88;
  font-weight: 600;
  text-align: left;
  padding: 8px;
}
.slot_table tr {
  border-color: white;
  border-style: solid;
  border-bottom-width: 6px;
  cursor: pointer;
}
.slot_modal tr {
  border-width: 0px 20px;
  border-color: rgb(231, 231, 231);
}
.slot_modal .MuiDialog-paper {
  background-color: rgb(231, 231, 231);
}
.slot_table {
  border-spacing: 0 1em;
  width: 100%;
}

.slot_form_group {
  padding: 10px 20px;
}
.slot_form_group.remark {
  padding: 0px 20px;
  /* width: 60% !important; */
}
.slot_form_group input {
  /* width: 50% !important; */
  border-radius: 10px;
  border: 1px solid #000;
}
.slot_form_group input:focus {
  outline: none;
  box-shadow: 0 0 0pt 0.4pt #000;
}
.slot_form_group label {
  font-size: 16px;
  font-weight: 600;
  padding-left: 5px;
}
.slot_form_group .tk_dropdown .dropdown-menu {
  border: 1px dashed rgb(158, 158, 158);
  transform: translate3d(0px, -428px, 0px) !important;
}
.slot_form_group .slot_btn {
  border: none;
  padding: 2px 25px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 5px;
  background-color: rgb(6, 248, 58);
}
.slot_close {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 15px;
  right: 15px;
}
.slot_date_controller {
  cursor: pointer;
}

@media all and (min-width: 780px) {
  .slot_table {
    width: 70%;
  }
  .slot_modal .slot_table {
    width: 100% !important;
  }
}
