.earning {
  margin: 10px;
  padding: 10px;
  position: relative;
}
.slot_data {
  margin: 10px;
  padding: 10px;
}
.success_button {
  border: 1px solid black;
  color: black;
  background-color: lightgreen;
  padding: 10px;
  border-radius: 2px;
}
.danger_button {
  border: 1px solid black;
  color: black;
  background-color: red;

  padding: 10px;
  border-radius: 2px;
}
.disable_color {
  background-color: white !important;
}
.disabled_success {
  background-color: lightgreen !important;
}
.white_button {
  border: 1px solid black;
  color: black;
  padding: 10px;
  border-radius: 2px;
  background-color: white !important;
}
.earning_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit_button {
  position: absolute;
  right: 5vw;
}
.t_earning_input,
.t_earning_input_date {
  border: 1px solid #3f51b5;
}
.t_earning_input_date {
  padding-left: 10px;
  border-radius: 0.25rem;
}

.month_total {
  background-color: var(--theme_color);
  padding: 20px 30px;
  margin: 55px 0 55px 0;
  width: 250px;
  justify-content: flex-start;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 15px;
}

.month_text {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}
.month_price {
  margin-top: 5px;
  font-size: clamp(1.5rem, 2vw, 1.9rem);
}

span {
  font-weight: 400;
}
.earn_type {
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.earn_type div:first-child {
  border-radius: 20px 0 0 20px;
}
.earn_type div:last-child {
  border-radius: 0px 20px 20px 0px;
}
.earn_type_item {
  margin: 20px 0 20px 0;
  border: 1px solid var(--theme_color);
  padding: 20px;
  font-weight: 900;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 100px;
  cursor: pointer;
}

.in_rupees .earn_type_item {
  cursor: auto;
}

.earn_price {
  font-size: clamp(0.9rem, 1.5vw, 2.2rem);
}
.earn_text {
  font-size: clamp(0.5rem, 1.2vw, 1.9rem);
}

.earn_chart {
  margin-top: 90px;
}

.earning_popup_container {
  background-color: white;
  box-shadow: 0px 3px 20px -10px;
  position: fixed;
  left: 55%;
  top: 55%;
  transform: translate(-40%, -50%);
  padding: 3vh 4vw;
  width: 60%;
  max-height: 80vh;
  overflow: auto;
  z-index: 999;
}

.earning_popup_container h3 {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.loading div {
  display: block;
  margin: 0 auto;
}

.earning_popup_container li {
  background-color: #3f51b5;
  border-radius: 5px;
  color: white;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.earning_popup_container li img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(13%)
    hue-rotate(206deg) brightness(104%) contrast(100%);
  margin-right: 10px;
  width: 20px;
}

.earning_popup_close_btn {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1290px) {
  .earn_type_item {
    flex: 1 0 calc(50% - 10px);
  }
  .earn_type div:nth-child(2) {
    border-radius: 0 20px 20px 0;
  }
  .earn_type div:nth-child(3) {
    border-radius: 20px 0 0 20px;
  }
  .earn_type div:nth-child(4) {
    border-radius: 0 20px 20px 0;
  }
  .earn_type div:nth-child(5) {
    border-radius: 20px 0 0 20px;
  }
  .earn_type div:last-child {
    border-radius: 0 20px 20px 0;
  }
}
@media screen and (max-width: 1000px) {
  .earn_type_item {
    flex: 1 0 calc(50% - 10px);
  }
}

@media screen and (max-width: 950px) {
  .earning {
    padding: 0 20px;
  }
  .earning_popup_container {
    left: 60%;
  }
  .box_inside {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .month_total {
    width: 200px;
  }
}

@media screen and (max-width: 780px) {
  .month_total {
    width: 200px;
  }
  .earning {
    padding: 0 0;
  }
  .earning_popup_container {
    left: 40%;
    width: 85%;
  }
}
@media screen and (max-width: 500px) {
  .edit_button {
    top: 2%;
  }
}
