.slot_calendar {
  margin-top: 0.5rem;
}
.gradeDiv {
  margin-top: -1rem;
  margin-right: 2rem;
}
.threeFlex {
  margin-left: 4%;
}
@media screen and (min-width: 1200px) {
  .slot {
    max-width: 12% !important;
  }
}
@media screen and (max-width: 1200px) {
  .t-slot {
    margin-left: 0%;

    padding-left: 0% !important;
  }

  .slot_calendar {
    margin-top: 0rem;
  }
  .dateDiv {
    position: relative;
    top: 10%;
    left: -2vh;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
  }
  .rounded {
    width: 80%;
    height: 100%;
  }
}
@media screen and (max-width: 780px) {
  .t-slot {
    margin-right: 0%;
    max-width: 1000px;
  }
  .dateLeft {
    font-size: 100%;
  }
  .gradeDiv {
    margin: 0%;
  }
  .dateDiv {
    position: relative;
    left: -10%;
    margin: 0;
  }
}
@media screen and (max-width: 600px) {
  .threeFlex {
    margin-left: 0%;
  }
  .dateDiv {
    left: 2%;
  }
  .slot_calendar {
    margin-right: 15% !important;
  }
  .t-slot {
    margin-top: 5%;
  }
}
@media screen and (max-width: 550px) {
  .threeFlex {
    display: flex;
    flex-direction: column;
  }
  .slot_calendar {
    margin-right: 15% !important;
  }
}
