.ticket_section {
	margin: 40px 10px 10px 280px;
	padding: 40px 0;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	-moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	-webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.ticket_section h1 {
	font-size: clamp(1.5rem, 2vw, 3rem);
}

.ticket_top h1 {
	padding: 20px 40px;
}
.ticket_info h1 {
	padding: 20px 0;
}
.ticket_info {
	padding: 0 40px;
}
.ticket_top {
	background-color: rgba(204, 204, 204, 0.6);
}

.ticket_section table,
.ticket_section th,
.ticket_section td,
.ticket_section thead {
	border: 1px solid #000;
	padding: 1em;
	font-size: clamp(0.7rem, 1.5vw, 1rem);
}

.ticket_section th,
.ticket_section td {
	border: 1px dashed #000;
}

.ticket_section tr td {
	padding: 25px 0;
}

.ticket_section button {
	margin: 20px 0;
}

.ticket_section form {
	margin-top: 90px;
	width: 60%;
	margin-left: 20%;
	font-weight: 900;
}

.ticket_desc {
	display: flex;
	flex-direction: column;
}
.ticket_desc textarea,
.ticket_desc input {
	/* border: 1.9px solid #000000;
	border-radius: 10px;
	padding: 12px; */
	border: none !important;
	box-sizing: border-box;
	filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.3));
	border-radius: 8px;
	margin-top: 12px;
	margin-bottom: 31px;
}

.ticket_desc input:focus {
	outline: none;
}

.ticket_section .attach {
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	font-size: clamp(0.9rem, 1.5vw, 1rem);
}
.ticket_section .attach {
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: clamp(0.9rem, 1.5vw, 1rem);
}
.attach label {
	padding: 10px;
	background: rgb(182, 182, 182);
	display: table;
	color: rgb(0, 0, 0);
	cursor: pointer;
}
#image {
	display: block;
}
input[type="file"] {
	display: none;
}

.imageBlog {
	font-style: normal;
	font-weight: 500;
	font-size: 27px;
	line-height: 40px;
}

.blogInput {
	font-style: normal;
	font-weight: 500;
	font-size: 29px;
	line-height: 43px;
}

.blogSubmit {
	background: #ffffff;
	box-shadow: 1px 1px 28px rgba(63, 81, 181, 0.27);
	border-radius: 8px;
	width: 255px;
	height: 50px;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	/* identical to box height */
	border: none;
	color: #74757a;
}

.blogDrop {
	/* border: 1px solid #a3a1a1; */
	box-sizing: border-box;
	filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.33));
	border-radius: 8px;
	background-color: white;
	height: 50px;
	outline: none;
	border: none;
}

.blogOptions {
	/* position: absolute; */
	/* display: none; */
	width: 414px;
	height: 290px;
	/* left: 861px;
top: 2269px; */
	/* display: flex; */
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background: #ffffff;
	box-shadow: 2px 2px 31px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	margin-top: 30px;
}
.blogOptions input {
	/* position: absolute; */
	width: 366px;
	height: 64px;
	/* left: 888px;
top: 2286px; */
	background-color: white;
	border: 1px solid #dfdfdf;
	box-sizing: border-box;
	border-radius: 8px;
	/* font-family: Poppins;
	font-style: normal; */
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	/* identical to box height */

	letter-spacing: 0.02em;

	color: #1c1a1a;
}
.blogSubmitContent {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media screen and (max-width: 780px) {
	.ticket_section {
		width: 100%;
	}

	.ticket_info {
		padding: 0 15px;
	}
	.ticket_section form {
		width: 80%;
	}
}

@media screen and (max-width: 500px) {
	.ticket_section form {
		width: 100%;
	}
}

.notification {
	min-height: 20px;
}

.noti_data {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.noti_content {
	display: flex;
	align-items: center;
	justify-content: center;
}
.attach_file {
	transform: rotate(45deg);
}
.notiNo {
	margin-right: 5px;
}

.noti_desc {
	word-wrap: break-word;
}
