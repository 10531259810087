
table {
  margin: 3rem auto;
  border-radius: 2rem;
  border: 1rem hidden #443c68;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px black;
  overflow: hidden;
  max-width: 80rem;
}

li,
p,
a,
td {
  font-size: 1rem;
  line-height: 1.5;
  border-width: 1px;
}

thead {
  font-size: 1.8rem;
  background-color: #4051b5;
  color: #fff;
}

th,
td {
  border: 1px solid #000000;
  min-width: 10rem;
  max-width: fit-content;
  padding: 1rem;
  line-height: 1.7;
  border-width: 1px;
  white-space:normal;
}
td {
  font-size: 0.8rem;
}

.tableh{
    background-color: transparent;
    color: white;
}

.tablecont_calendar {
  margin-right: 50px;

}