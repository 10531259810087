.user_details {
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 80%;
  margin-top: 2rem;
}

.user_info {
  margin-top: 1rem;
}

.user_info label {
  margin: 1rem 0 0rem;
  font-size: 0.8rem;
  font-weight: 900;
}
.user_info input {
  margin-top: 0;
  margin-left: 0;
  border-radius: 10px;
  border: 1px solid #212529;
}
.user_info input:focus {
  outline: none;
  border: 2px solid #3f51b5;
}
.user_info input::-webkit-outer-spin-button,
.user_info input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user_info button.save_btn {
  border: none;
  margin-top: 2rem;
  padding: 8px 25px;
  text-align: center;
  border-radius: 19px;
  background-color: #09db31;
  color: white;
  font-weight: 900;
}
.search_input {
  position: relative;
}

.search_input i {
  position: absolute;
  right: 1px;
  border-radius: 0px 10px 10px 0;
  top: 1px;
  background-color: #f1f1f1;
  padding: 4px;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
  border: 1px solid #212529 !important;
}
.react-tel-input .form-control {
  border: 1px solid #212529 !important;
}
