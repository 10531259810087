input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.newTable {
  overflow-x: auto;
  width: 100%;
}
table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}
td {
  height: 10%;
}
.new_booking_add_user_container {
  position: fixed;
  left: 55%;
  top: -5%;
  overflow-y: auto;

  /* padding: 0%;
  padding-left: 10%;
  padding-top: 5%; */
  /* background-color: rgb(116, 160, 241); */
}
.s-bookingNew {
  margin-left: 0%;
}
/* .react-tel-input .form-control {
  margin-top: 10%;
  width: 65% !important;
} */

@media screen and (min-width: 1200px) {
  .s-bookingNew {
    /* margin: 5%; */
  }
}
@media screen and (max-width: 1000px) {
  .new_booking_add_user_container {
    left: 65%;
    width: 60%;
  }
}
@media screen and (max-width: 780px) {
  .new_booking_add_user_container {
    height: 80%;
    left: 45%;
    width: 80%;
    top: 10%;
    padding-left: 10%;
  }
  .alertBoxNew {
    top: 2%;
    left: 45%;
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .new_booking_add_user_container {
    width: 80%;
  }
}
