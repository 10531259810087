.registration_action_item {
  background-color: rgb(243, 179, 160);
}
.registration_main {
  display: flex;
  flex-direction: column;
}
.record {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: none; */
}
@media screen and (max-width: 450px) {
  .registration_tabel_action {
    display: felx;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    justify-content: space-between;
    margin-top: 0%;
  }
  .registration_actions {
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-top: 0%;
  }
  .registration_main {
    flex-direction: row;
    justify-content: space-between;
  }
  .record {
    flex-direction: column;
    align-items: center;
  }
  .search2 {
    display: none;
  }
}
