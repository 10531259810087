.profile_info {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 20px;
}
.double_drop .profile_info {
  width: 50%;
}
.profile_info label {
  font-size: 18px;
  font-weight: 600;
  padding-left: 5px;
  margin-bottom: 0px !important;
}
.profile_info input,
.profile_edit .PhoneInputInput {
  height: 40px;
  border-radius: 10px;
  border: 1px solid #000;
}
.profile_info input:focus,
.profile_edit .PhoneInputInput:focus {
  outline: none;
  box-shadow: 0 0 0pt 0.4pt #000;
}

.p_60 {
  padding: 60px 0;
}

@media all and (min-width: 780px) {
  .profile_info {
    width: 75%;
  }

  .p_60 {
    padding: 60px;
  }
  .double_drop .profile_info {
    width: 40%;
  }
}
