.flex-container {
  padding: 3%;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
}
.personal-detail {
  padding: 2%;
}
.space-between {
  justify-content: space-between;
}
.avatar {
  background-color: purple;
  width: 200px;
  height: 200px;
  border-radius: 100px;
}
