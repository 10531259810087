.referral_count {
  display: flex;
  color: white;
  justify-content: space-between;
  width: 60%;
  margin-top: 2rem;
}

.referral_count div {
  padding: 1rem 1.5rem;
  border-radius: 20px;
  text-align: center;
}
.referral_count div p {
  margin-bottom: 0;
}

.referral_table {
  margin-top: 2rem;
  width: 100%;
  border-collapse: collapse;
}
.referral_table tr {
   cursor: pointer;
  }

.referral_table th {
  color: #212529;
  font-weight: 600;
}

.referral_table td {
  padding: 10px;
  color: #3f51b5;
}

.referral_table tr:nth-child(odd) {
  background-color: rgb(240, 240, 240);
}

