/* .os-registration {
} */
.os-reg input {
  width: 65%;
}

@media screen and (max-width: 780px) {
  .form_row {
    flex-direction: column !important;
  }
  .flexbox {
    flex-direction: column;
  }
}
