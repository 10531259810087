.booking_item_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px 50px;
}

.booking_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  width: 20%;
  min-width: 150px;
  height: 130px;
  cursor: pointer;
  margin: 20px 10px;
  color: #fff;
  background-color: aqua;
}
.booking_item:nth-child(1) {
  background-color: rgb(26, 165, 119);
}
.booking_item:nth-child(2) {
  background-color: rgb(31, 134, 230);
}
.booking_item:nth-child(3) {
  background-color: rgb(230, 31, 74);
}
.booking_item:nth-child(4) {
  background-color: rgb(31, 190, 230);
}
.booking_item:nth-child(5) {
  background-color: rgb(230, 111, 31);
}
.booking_item:nth-child(6) {
  background-color: rgb(54, 68, 196);
}
.booking_item:nth-child(7) {
  background-color: rgb(72, 179, 86);
}
.booking_item:nth-child(8) {
  background-color: rgb(0, 201, 10);
}
.booking_item:nth-child(9) {
  background-color: rgb(192, 29, 0);
}
.booking_item:nth-child(10) {
  background-color: rgb(184, 184, 184);
}
.booking_item:nth-child(11) {
  background-color: rgb(238, 107, 67);
}
.booking_item:nth-child(12) {
  background-color: rgb(141, 35, 202);
}
.test {
  height: 100vh;
}

.mainContent {
  width: 100%;
  height: 100vh;
  padding: 3%;
}
.title {
  font-weight: 400;
  padding: 2%;
}
.temp {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
}
.bookings {
  height: 200px;
  color: pink;
}
.delete_buttton {
  color: white;
  background-color: red;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border: red;
}
.edit_btn {
  color: white;
  background-color: rgb(16, 184, 16);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.new_booking_add_user_container {
  background-color: white;
  box-shadow: 0px 3px 30px -15px black;
  width: 55%;
  position: absolute;
  left: 45%;
  top: 10%;
  transform: translate(-45%, 10%);
  padding: 2rem 2.5rem;
}
.new_booking_add_user_form_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new_booking_add_user_container fieldset {
  display: block;
}
.new_booking_add_user_close_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.new_booking_add_user_container input {
  width: 60%;
  margin: 1rem;
}

.new_booking_add_user_submit_btn {
  background-color: rgb(24, 83, 248);
  border: none;
  color: White;
  font-weight: 900;
  border-radius: 5px;
  margin: 1rem 1rem;
  width: 20%;
  padding: 5px;
}

.s_converted_edit_button,
.s_converted_feedback_button {
  border: none;
  background-color: #3f51b5;
  padding: 5px;
  border-radius: 5px;
  color: white;
  margin: 5px;
  font-weight: 900;
}
