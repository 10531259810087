.mainTable {
  overflow-x: auto;
  width: 100%;
  position: relative;
  left: 0%;
}
table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}
th,
td {
  text-align: left;
}

.dateLeft {
  position: relative;
  left: 0%;
}
.teacher {
  position: relative;
  left: -40%;
  margin: 0.3rem;
}
.slot_calendar {
  position: relative;
  left: 0%;
  margin-top: 0.3rem;
}
.threeFlex {
  width: 100%;
  flex-basis: 100%;
}

@media screen and (min-width: 1200px) {
  .mainBook {
    padding-left: 5%;
  }
}

@media screen and (max-width: 1100px) {
  .twoFlex {
    display: flex;
    flex-direction: column;
  }
  .slot_calendar {
    margin: 0%;
  }
  .teacher {
    position: relative;
    left: 0%;
    margin: 0%;
  }
}
@media screen and (min-width: 780px) and (max-width: 1200px) {
  .mainBook {
    padding-left: -15% !important;
  }
  .dateLeft {
    font-size: 150%;
  }
  .threeFlex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 780px) {
  .dateLeft {
    position: relative;
    left: 0%;
  }
  .teacher {
    position: relative;
    left: -5%;
  }
  .slot_calendar {
    position: relative;
    left: 0%;
  }
  .mainTable {
    position: relative;
    left: 0%;
  }
  .mainBook {
    margin-right: 0%;
  }
  .twoFlex {
    display: flex;
    flex-direction: row;
  }
  .dateLeft {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .threeFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .twoFlex {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 5%;
  }
  .teacher {
    position: relative;
    /* left: 25%; */
    margin-left: 35%;
  }
}
@media screen and (max-width: 500px) {
  .threeFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .twoFlex {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0%;
  }
  .teacher {
    padding: 0;
  }
  .slot_calendar {
    padding: 0;
    margin: 0;
  }
}
